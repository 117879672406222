<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Ventas</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de ventas</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenuQuery('venta', 1)"
                    v-if="$can('transaccion.menu.nueva.venta')"
                    >
                    <a-icon type="plus" /> Nueva Venta
                </a-button>
            </a-col>

            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale" 
                    placeholder="Fecha Inicio"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 10 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale"
                    placeholder="Fecha Final" 
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 350px">
                <a-input-search placeholder="Buscar por codigo de venta" @search="onSearchSalida">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

		<br>

        <a-table 
                :columns="columns" 
                :data-source="ventas" 
                :pagination="paginate"
                :loading="loading"
                :ellipsis="true"
                size="small"
                :rowKey="record => record.venta_id"
                :scroll="{ x: 980 }"
                @change="handlePagination"
                class="custom-table"
                >

                <template slot="codigo" slot-scope="item">
                    <span>{{ item.codigoVenta }}</span><br>
                    <template v-if="item.tiposalidaID === 2">
                        <span>{{ item.codigoControl }}</span><br>
                    </template>
                    <span style="font-size:11px; font-weight:700;">{{ item.tituloVenta }}</span><br>
                    <span v-if="item.descripcionVenta === 'PRODUCTO'" style="font-size:11px; font-weight:700; color:#188802;">{{ item.descripcionVenta }}</span>
                    <span v-if="item.descripcionVenta === 'SERVICIO'" style="font-size:11px; font-weight:700; color:#016bd5;">{{ item.descripcionVenta }}</span>
                </template>

                <template slot="cliente" slot-scope="item">
                    <span>{{ item.cliente.nombreFactura }}</span><br>
                    <span style="font-size:10px; font-weight:700;">Usuario : {{ (item.usuario) ? item.usuario : 'No registrado' }}</span><br>
					<span v-if="item.estado == 'APROBADO'" style="color:#188802;font-size: 11px;">APROBADO</span>
                    <span v-if="item.estado == 'ANULADO'" style="color:rgb(192, 3, 3); font-weight:700; font-size: 11px;">ANULADO</span>
                </template>

                <template slot="plancuenta" slot-scope="item">
                    <span v-if="item.plancuenta_id === 2" style="color:#C44100; font-weight:700;">{{ item.nombre }}</span>
                    <span v-else>{{ item.nombre }}</span>
                </template>

                <template slot="fechaventa" slot-scope="item">
                    {{ (item.fechaVenta) ? utils.formatOnlyDate(item.fechaVenta) : 'No registrado' }} {{ (item.horaVenta) ? item.horaVenta : '' }}
                </template>

                <template slot="estadoCredito" slot-scope="item">
                    <template v-if="config.habilitararqueocaja === 'ACTIVADO' && $can('manager.arqueo.de.caja') === true">
                        <template v-if="item.estado === 'APROBADO'">
                            <template v-if="item.estado_arqueo === 'VENTA CON ARQUEO'">
                                <span style="font-size:9px; font-weight:700; color:black;">Arqueo Habilitado</span>
                                <a-tag color="#108ee9" v-if="item.is_venta_arqueo === 'ARQUEO EN ESPERA'">
                                    EN ESPERA
                                </a-tag>
                                <a-button type="primary" size="small" block v-if="item.is_venta_arqueo === 'ARQUEO EN ESPERA'">
                                    <span style="font-size:11px; margin-left:-5px !important;">Cobrar Arqueo</span>
                                </a-button>
                                <a-tag color="#209004" v-if="item.is_venta_arqueo === 'ARQUEO REALIZADO'">
                                    COBRADO
                                </a-tag>
                            </template>
                            <template v-else>
                                <template v-if="item.estado_credito === 'PENDIENTE'">
                                    <a-tag color="#f50">
                                        {{ item.estado_credito }}
                                    </a-tag>
                                    <template v-if="item.cuentascobrar.length > 0">
                                        <center><span style="color:#f50;">{{ utils.formatMoney(item.cuentascobrar.at(-1).saldoActualCobrar, config.ventadecimal, '.', '') }}</span></center>
                                    </template>
                                    <template v-if="item.cuentascobrar.length === 0">
                                        <center><span style="color:#f50;">en espera</span></center>
                                    </template>
                                </template>
                                <template v-else>
                                    <a-tag color="#209004">
                                        COBRADO
                                    </a-tag>
                                </template>
                            </template>
                        </template>

                        <template v-if="item.estado === 'ANULADO'">
                            <a-tag color="#C00303">
                                <span>ANULADO</span>
                            </a-tag>
                        </template>
                    </template>

                    <template v-else>
                        <template v-if="item.estado === 'APROBADO'">
                            <template v-if="item.estado_credito === 'PENDIENTE'">
                                <a-tag color="#f50">
                                    {{ item.estado_credito }}
                                </a-tag>
                                <template v-if="item.cuentascobrar.length > 0">
                                    <center><span style="color:#f50;">{{ utils.formatMoney(item.cuentascobrar.at(-1).saldoActualCobrar, config.ventadecimal, '.', '') }}</span></center>
                                </template>
                                <template v-if="item.cuentascobrar.length === 0">
                                    <center><span style="color:#f50;">en espera</span></center>
                                </template>
                            </template>
                            <template v-else>
                                <a-tag color="#209004">
                                    COBRADO
                                </a-tag>
                            </template>
                        </template>

                        <template v-if="item.estado === 'ANULADO'">
                            <a-tag color="#C00303">
                                <span>ANULADO</span>
                            </a-tag>
                        </template>
                    </template>
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>VENTA ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CLIENTE:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>TIPO SALIDA:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>{{ item.venta_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.codigoVenta }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.almacen.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.cliente.nombreFactura }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.tiposalida.nombre }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">

                            <template v-if="item.cuentascobrar.length === 0">
                                <a-menu-item :key="buttonKeyEditarVenta" v-if="$can('transaccion.editar.venta.realizada')">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>{{ item.codigoVenta }}</span>
                                        </template> 
                                        <a href="#" @click.prevent="openModalEditarVenta(item)" v-if="item.is_venta_editar === 'SIN EDITAR' && item.is_factura !== 'FACTURA'">
                                            <a-icon type="edit" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Editar Venta {{ item.codigoVenta }}</strong>
                                        </a>

                                        <a href="#" @click.prevent="openModalEditarVenta(item)" v-if="item.is_venta_editar === 'REALIZADO' && config.habilitareditarventafrecuente === 'ACTIVADO'">
                                            <a-icon type="retweet" :style="{ fontSize: '18px', color: '#08AC00' }"/> &nbsp;<strong>Editar Venta {{ item.codigoVenta }}</strong>
                                        </a>
                                        <template v-if="item.is_venta_editar === 'REALIZADO' && config.habilitareditarventafrecuente === 'DESACTIVADO'" >
                                            <a-icon type="check" :style="{ fontSize: '18px', color: '#08AC00' }"/> &nbsp;<strong>Venta Actualizado {{ item.codigoVenta }}</strong>
                                        </template>
                                    </a-tooltip>
                                </a-menu-item>
                            </template>

                            <a-menu-item :key="buttonKeyVisualizarComprobante">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>{{ item.codigoVenta }}</span>
                                    </template>
                                    <a href="#" @click.prevent="abrirModalSalida(item)"><a-icon type="eye" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Visualizar Comprobante</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyVerReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>{{ item.codigoVenta }}</span>
                                    </template>
                                    <a href="#" @click.prevent="reporteCarta(item.venta_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante PDF</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyImprimirReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>{{ item.codigoVenta }}</span>
                                    </template>
                                    <a href="#" @click.prevent="imprimirTermico(item.venta_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <template v-if="item.plancuentaID === 2">
                                <a-menu-item :key="buttonKeyImprimirCuentaCobrar" v-if="$can('transaccion.gestion.cuentas.por.cobrar')">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>{{ item.codigoVenta }}</span>
                                        </template>
                                        <a href="#" @click.prevent="openModalCuentaCobrar(item)"><a-icon type="profile" :style="{ fontSize: '18px', color: '#f50' }"/>
                                            <template v-if="item.estado_credito == 'PENDIENTE' && item.is_factura == 'RECIBO'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Cuenta por Cobrar : {{ item.codigoVenta }}</strong>
                                            </template>
                                            <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'RECIBO'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Ver Cuotas : {{ item.codigoVenta }}</strong>
                                            </template>
                                            <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'FACTURA'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Ver Cuotas : {{ item.codigoVenta }}</strong>
                                            </template>
                                        </a>
                                    </a-tooltip>
                                </a-menu-item>
                                <!--
                                <a-menu-item :key="buttonKeyImprimirFacturarCuentaCobrar">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>Facturar Cuenta : {{ item.codigoVenta }}</span>
                                        </template>
                                        <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'RECIBO'">
                                            <a href="#" @click.prevent="abrirFacturarCredito(item)"><a-icon type="profile" :style="{ fontSize: '18px', color: '#0652c8' }"/>
                                                &nbsp;<strong style="color:#0652c8; font-weight:700;">Facturar Cuenta : {{ item.codigoVenta }}</strong>
                                            </a>
                                        </template>
                                        <template v-if="item.estado_credito == 'PENDIENTE' && item.is_factura == 'RECIBO'">
                                            <a-icon type="profile" :style="{ fontSize: '18px', color: '#0652c8' }"/>
                                            &nbsp;<strong style="color:#0652c8; font-weight:700;">Facturar Cuenta en Espera : {{ item.codigoVenta }}</strong>
                                        </template>
                                        <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'FACTURA'">
                                            <a-icon type="profile" :style="{ fontSize: '18px', color: '#0652c8' }"/>
                                            &nbsp;<strong style="color:#0652c8; font-weight:700;">Cuenta Facturado: {{ item.codigoVenta }}</strong>
                                        </template>
                                    </a-tooltip>
                                </a-menu-item>
                                -->
                            </template>

                            <template v-if="item.cuentascobrar.length === 0">
                                <template v-if="item.estado == 'APROBADO'">
                                    <a-menu-item @click.once="AnularVenta(item.venta_id)" :key="buttonKeyAnularVenta" v-if="$can('transaccion.anular.venta.realizada')">
                                        <a-tooltip placement="topLeft">
                                            <template slot="title">
                                                <span>{{ item.codigoVenta }}</span>
                                            </template>
                                            <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/><strong style="color:#cc2400; font-weight:700;">Anular Venta</strong>
                                        </a-tooltip>
                                    </a-menu-item>
                                </template>
                            </template>
                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template>
        </a-table>

        <a-modal
            v-model="modalDetalleSalida"
            title="Detalle Salida"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="780"
            :zIndex="1050"
            >

            <div class="mb-12">
                <a-row>
                    <a-col :xs="24">
                    <div class="invoice-title">
                        <span style="font-weight: 700;">CODIGO DE SALIDA</span>
                        <span style="float: right; font-weight: 700;"><strong># {{ detalle.codigoVenta }}</strong></span>
                    </div>
                    <a-divider />
                    <a-row>
                        <a-col :xs="24" :md="12">
                        <span>
                            <p>Sucursal: {{ detalle.almacen }}</p>
                            <p>Cliente: {{ detalle.cliente }}</p>
                            <p>NIT / CI: {{ detalle.nitcliente }}</p>
                            <p>Moneda: {{ detalle.tipocambio }}</p>
                            <p>
                            <span v-if="detalle.estado === 'APROBADO'" style="color:green; font-weight: 700;">
                                Estado: VENTA {{ detalle.estado }}
                            </span>
                            <span v-if="detalle.estado === 'ANULADO'" style="color:red; font-weight: 700;">
                                Estado: VENTA {{ detalle.estado }}
                            </span>
                            </p>
                        </span>
                        </a-col>
                        <a-col :xs="24" :md="12">
                        <span>
                            <p>Usuario: {{ detalle.usuario }}</p>
                            <p>Fecha: {{ detalle.fechaVenta }}</p>
                            <p>Hora: {{ detalle.horaVenta }}</p>
                            <p>Tipo Venta: {{ detalle.plancuenta }}</p>
                        </span>
                        </a-col>
                    </a-row>
                    </a-col>
                </a-row>
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                            </div>
                            <div class="panel-body">
                                <div class="box-body table-responsive no-padding">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th><strong>ITEM</strong></th>
                                                <th class="text-center"><strong>ARTICULO</strong></th>
                                                <th class="text-center"><strong>CANT</strong></th>
                                                <th class="text-center"><strong>P.VENTA</strong></th>
                                                <th class="text-center"><strong>DESC</strong></th>
                                                <th class="text-right"><strong>SUBTOTAL</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="showModalSalidaListado.length > 0">
                                                <tr v-for="(item, index) in showModalSalidaListado" :key="index">
                                                    <td>{{ index + 1}}</td>
                                                    <td>{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                    <td class="text-center">{{ item.cantidad }}</td>
                                                    <td class="text-center">{{ item.precioVenta }}</td>
                                                    <td class="text-center">{{ item.descuento }}</td>
                                                    <td class="text-right">{{ item.subtotal }}</td>
                                                </tr>
                                            </template>

                                            <template v-else>
                                                <tr v-for="(item, index) in detalle.listaServicios" :key="index">
                                                    <td>{{ index + 1}}</td>
                                                    <td>{{ item.codigo }} {{ item.nombre }}</td>
                                                    <td class="text-center">{{ item.cantidad }}</td>
                                                    <td class="text-center">{{ item.precioVenta }}</td>
                                                    <td class="text-center">{{ item.descuento }}</td>
                                                    <td class="text-right">{{ item.subtotal }}</td>
                                                </tr>
                                            </template>
                                        </tbody> 
                                        <tfoot>       
                                            <tr>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                                <td class="thick-line text-right">{{ detalle.simbolo }} {{ detalle.montoTotal }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Descuento</strong></td>
                                                <td class="no-line text-right">{{ detalle.simbolo }} {{ detalle.descuento }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"><strong>SON:</strong></td>
                                                <td class="no-line">{{ detalle.montoLiteral }}</td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Total</strong></td>
                                                <td class="no-line text-right" style="font-weight:700;">{{ detalle.simbolo }} {{ detalle.totalVenta }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td v-if="this.detalle.plancuentaID == 2" class="no-line"><strong>Fecha Plazo de Pago:</strong> {{ detalle.fechaCredito }}</td>
                                                <td v-else class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td v-if="this.detalle.plancuentaID == 2" class="no-line text-center"><strong>Abonado</strong></td>
                                                <td v-else class="no-line text-center"><strong>Efectivo</strong></td>
                                                <td v-if="this.detalle.plancuentaID == 2" bgcolor="#d0ff8e" class="no-line text-right">{{ detalle.simbolo }} 0.00</td>
                                                <td v-else bgcolor="#d0ff8e" class="no-line text-right">{{ detalle.simbolo }} {{ detalle.efectivo }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td v-if="this.detalle.plancuentaID == 2" class="no-line text-center"><strong>Saldo</strong></td>
                                                <td v-else class="no-line text-center"><strong>Cambio</strong></td>
                                                <td v-if="this.detalle.plancuentaID == 2" bgcolor="#d0ff8e" class="no-line text-right">{{ detalle.simbolo }} {{ detalle.totalVenta }}</td>
                                                <td v-else bgcolor="#d0ff8e" class="no-line text-right">{{ detalle.simbolo }} {{ detalle.cambio }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalleSalida = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="showModalFacturarCredito">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <form v-on:submit.prevent accept-charset="UTF-8" data-parsley-priority-enabled data-parsley-validate="true">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true" @click="showModalFacturarCredito = false">&times;</span></button>
                                        <h4 class="modal-title">GENERAR FACTURA INDIVIDUAL</h4>
                                    </div>
                                    <div class="modal-body">
                                        <div class="col-sm-12">
                                            <div class="box-footer box-comments">
                                                <div class="box-comment">
                                                    <span><strong>CLIENTE : </strong>{{ dataVenta.cliente }} </span><br>
                                                    <span><strong>CODIGO VENTA : </strong>{{ dataVenta.codigoVenta }} </span><br>
                                                    <span><strong>ESTADO : </strong>{{ dataVenta.estado }} </span><br>
                                                    <span v-if="dataVenta.estado_credito == 'COBRADO'"><strong>ESTADO DEL CREDITO : </strong><span style="color:green;">{{ dataVenta.estado_credito }}</span> </span><br>
                                                    <span v-if="dataVenta.estado_credito == 'PENDIENTE'"><strong>ESTADO DEL CREDITO : </strong><span style="color:red;">{{ dataVenta.estado_credito }}</span> </span><br>
                                                    <span><strong>FECHA : </strong>{{ dataVenta.fechaVenta }} {{ dataVenta.horaVenta }}</span><br>
                                                    <span><strong>SUBTOTAL : </strong>{{ dataVenta.montoTotal }} </span><br>
                                                    <span><strong>TOTAL : </strong>{{ dataVenta.totalVenta }} </span><br>
                                                    <span><strong>MOENDA : </strong>{{ dataVenta.tipocambio }} </span><br>
                                                    <span><strong>TIPO SALIDA : </strong>{{ dataVenta.tiposalidaID }} </span><br>
                                                    <span><strong>TIPO VENTA : </strong>{{ dataVenta.plancuentaID }} </span><br>
                                                    <span><strong>USUARIO : </strong>{{ dataVenta.usuario }} </span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr>

                                        <div class="col-sm-12">
                                            <div class="callout bg-black-active">
                                                <center><font size="7" color="#0ed104"><strong><p>NIT : {{ cliente.nit }}</p></strong></font></center>
                                                <center><font size="6" color="#0ed104"><strong><p>{{ cliente.nombre }}</p></strong></font></center>
                                            </div>
                                        </div>

                                        <div class="form-group col-md-12 col-xs-12">
                                            <label>Buscar Cliente  <span style="font-weight:700; font-size:16px; color: rgb(32, 80, 129);">(F4)</span></label>
                                            <a-select :value="cliente_id" @change="onChageClienteNIT" id="SelectCliente" style="width: 100%">
                                                <a-select-option v-for="(value, index) in lista_clientes_all" :key="index" :value="value.cliente_id"> {{ value.nitFactura }} &nbsp;&nbsp;{{ value.nombreCliente }}</a-select-option>
                                            </a-select>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label>NIT /CI: </label>
                                            <input type="text" class="form-control" id="inputCliente" ref="inputCliente" v-model="cliente.nit" placeholder="5744678011" onfocus="myFunction(this)" v-on:keyup="validacionNIT()" autofocus>
                                        </div>

                                        <div class="form-group col-md-12">
                                            <label>Nombre del Cliente o Razón Social: </label>
                                            <input type="text" class="form-control" v-model="cliente.nombre" placeholder="Nombre Cliente" onfocus="myFunction(this)">
                                        </div>
                                        <div class="col-md-12">
                                            <div class="callout bg-black-active">
                                                <center><label>Precio Total</label></center>
                                                <center><font size="7" color="#0ed104"><strong><p>{{ dataVenta.totalVenta }}</p></strong></font></center>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal-footer">								
                                        <button type="button" class="btn btn-secondary really-close-the-modal" @click="showModalFacturarCredito = false"><i class="fa fa-ban"></i> CERRAR (ESC)</button>
                                        <button type="submit" class="btn" style="background-color:#0048ce; color:#ffffff; font-weight: 700;" @click.once="realizarFacturacion()" :key="buttonKeyRealizarFacturacion" v-if="dataVenta.estado_credito == 'COBRADO'"> <i class="fa fa-check"></i> REALIZAR FACTURACION</button>
                                        <button type="submit" class="btn" style="background-color:#0048ce; color:#ffffff; font-weight: 700;" v-if="dataVenta.estado_credito == 'PENDIENTE'" disabled> <i class="fa fa-check"></i> REALIZAR FACTURACION</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- GESTION CUENTA COBRAR -->
        <a-modal
            v-model="modalCuentaCobrar"
            title="Gestion Cuentas por Cobrar"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1300"
            :zIndex="1048"
            >

            <div class="row mb-12">
                <modulo-cuenta-por-cobrar 
                    :monedasimbolo="monedasimbolo" 
                    :ventaid="venta.venta_id"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalCuentaCobrar = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <!-- GESTION EDITAR VENTA -->
        <a-modal
            v-model="modalEditarVenta"
            title="GESTION EDITAR VENTA"
            :closable="false"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1300"
            :zIndex="1040"
            >

            <div style="margin-left: 30px;">
                <NuevaVenta
                    :nuevaventanaventaid="1"
                    :estadomoduloventa="`EDITAR`"
                    :updateventa="this.venta"
                    @successEditarVenta="dataSuccessEditarVenta"
                    :estadoarqueocaja="``"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalEditarVenta = false">
                    Cerrar Ventana
                </a-button>
            </template>
        </a-modal>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar Ventas"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" :span="24" v-if="$can('transaccion.consultar.venta.por.sucursales')">
                        <label for="almacen" class="control-label">Sucursal:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.almacen_id }" @change="onChageAlmacen"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="salida" class="control-label">Tipo de Salida:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.tiposalida_id }" @change="onChageTipoSalida"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_tiposalidas" :key="index" :value="value.tiposalida_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="venta" class="control-label">Tipo de Venta:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.plancuenta_id }" @change="onChageTipoVenta"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_plancuenta" :key="index" :value="value.plancuenta_id"> {{ value.nombre_corto }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('transaccion.consultar.venta.por.usuario')">
                        <label for="proveedor" class="control-label">Usuarios:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.onlyusuario }" @change="onChageUsuario"  style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_usuarios" :key="index" :value="value.usuario"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="proveedor" class="control-label">Clientes:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.cliente_id }" @change="onChageCliente" show-search option-filter-prop="children" :filter-option="filterOptionCliente" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_clientes" :key="index" :value="value.cliente_id"> {{ value.nombreCliente }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="nitProveedor" class="control-label">NIT / CI Clientes:</label>
                        <a-select size="large" label-in-value :value="{ key: consulta.cliente_id }" @change="onChageClienteNit" show-search option-filter-prop="children" :filter-option="filterOptionClienteNIT" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_nitclientes" :key="index" :value="value.cliente_id"> {{ value.nitFactura }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="obtenerListadoActual">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
			<div style="margin-top: 250px;">
				<vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
				<br><br>
				<center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
			</div>
		</div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'venta_id', 
            title: 'Codigo', 
            dataIndex: '',
            key: 'codigo',
            scopedSlots: { customRender: 'codigo' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'venta_id',
            title: 'Cliente', 
            dataIndex: '',
            key: 'cliente',
            scopedSlots: { customRender: 'cliente' },
            width: '30%',
            ellipsis: true
        },
        { 
            id: 'venta_id', 
            title: 'Tipo Venta', 
            dataIndex: 'plancuenta',
            scopedSlots: { customRender: 'plancuenta' }, 
            width: '13%'
        },
        { 
            id: 'venta_id',
            title: 'Subtotal', 
            dataIndex: 'montoTotal',
            width: '12%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'venta_id',
            title: 'Desc', 
            dataIndex: 'descuento',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'venta_id',
            title: 'Total', 
            dataIndex: 'totalVenta',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'venta_id',
            title: 'Estado', 
            dataIndex: '',
            key: 'estadoCredito',
            scopedSlots: { customRender: 'estadoCredito' }, 
            width: '10%'
        },
        { 
            id: 'venta_id', 
            title: 'F.Venta', 
            dataIndex: '',
            key: 'fechaventa',
            scopedSlots: { customRender: 'fechaventa' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '10%'
        },
        { 
            id: 'venta_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    import ModuloCuentaPorCobrar from '../Modulos/ModuloCuentaPorCobrar.vue'
    import NuevaVenta from '../../Venta/Modulos/NuevaVenta.vue'

    import { 
		URI_USUARIO_LISTAR,
        URI_PLANCUENTA_LISTAR,
        URI_CLIENTE_LISTAR,
        URI_CLIENTE_LISTAR_NIT,
        URI_VENTA_ANULAR,
        URI_VENTA_CONVERTIR_FACTURA,
        URI_ALMACEN_GET_LISTAR,
        URI_TIPOSALIDA_GET_LISTAR_FACTURACION
	} from '@/utils/constants'

    export default {

        props: [
			'monedaliteral',
			'nuevaventanaventaid', 
			'ismarcaizquierdalistaventa',
			'monedasimbolo',
			'userid', 
			'almacenid', 
			'habilitarsolicitante', 
			'codigosolicitante',
			'titulosolicitante', 
			'titulosolicitanteresponsable', 
			'habilitardescuentomanualventa',
			'habilitardescuentoselectventa',
			'deshabilitarvalidareditarprecioventa',
			'istotallistaproductoventa',
			'habilitarboletacobro',
			'habilitartipocambioventa',
			'habilitararqueocaja',
			'habilitarcontrolcodigobarras',
			'ventadecimal',
			'generarlenghtcodigobarra',
			'utilidadporcentaje',
			'statecodigobarradefault',
			'disabledvencimiento',
			'preciocifradecimal',
			'preciocifraentera',
			'precioreglatres'
		],

        components: {
            ModuloCuentaPorCobrar,
            NuevaVenta
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
                consulta: {
                    almacen_id: 0,
                    tiposalida_id: 'Seleccionar',
					plancuenta_id: 'Seleccionar',
					onlyusuario: 'Seleccionar',
                    cliente_id: 'Seleccionar',
                    nit_cliente: 'Seleccionar',
                    keyword: '',
                    fecha_inicio: '',
                    fecha_final: '',
                    nombreAlmacen: '',
                    nombreTipoSalida: '',
                    nombrePlanCuenta: '',
                    nombreUsuario: '',
                    nombreCliente: '',
                    nombreClienteNIT: ''
                },
                lista_almacenes: [],
                lista_tiposalidas: [],
                lista_plancuenta: [],
				lista_clientes: [],
				lista_usuarios: [],
                lista_nitclientes: [],
                lista_productos: [],
                buttonKey: 1,
                showModal: false,
                detalle: {
                    codigoVenta: '',
                    fechaVenta: '',
                    horaVenta: '',
                    montoTotal: '',
                    descuento: '',
                    totalVenta: '',
                    efectivo: '',
                    cambio: '',
                    usuario: '',
                    cliente: '',
                    nitcliente: '',
                    tipocambio: '',
                    tipocambioValor: '',
                    almacen: '',
                    simbolo: '',
                    montoLiteral: '',
                    plancuentaID: '',
                    fechaCredito: '',
                    nombreMoneda: '',
                    plancuenta: '',
                    estado: '',
                    listaServicios: []
                },
                showModalSalidaListado: [],
                list: [],
                url: '',
				search: { keyword: '' },
				spinnerloading: false,
                estadoTipoSalida: 0,
                listadosalida_ajuste: [],
                listadosalida: [],
				disabledButton: false,
				disabledButtonUsuario: false,
				lista_permisos: [],
                buttonKeyDescargarReporte: 10,
                buttonKeyVerReporte: 20,
                buttonKeyImprimirReporteTermico: 30,
                buttonKeyVerReporteTipoCambio: 40,
                buttonKeyAnularVenta: 50,
                buttonKeyImprimirReporte: 60,
                buttonKeyImprimirCuentaCobrar: 70,
                buttonKeyImprimirFacturarCuentaCobrar: 80,
                buttonKeyEditarVenta: 90,
                buttonKeyVisualizarComprobante: 100,
                showModalFacturarCredito: false,
                modalDetalleSalida: false,
                modalConsultaOpciones: false,
                dataVenta: {
					cliente: '',
					codigoVenta: '',
					estado: '',
					estado_credito: '',
					fechaVenta: '',
					horaVenta: '',
					montoTotal: '',
					tipocambio: '',
					tiposalidaID: '',
					plancuentaID: '',
					totalVenta: '',
					usuario: ''
				},
                venta: {
                    venta_id: '',
                    almacenID: '',
                    plancuentaID: '',
                    tiposalidaID: '',
                    clienteID: '',
                    codigoVenta: '',
                    fechaVenta: '',
                    nitFactura: '',
                    nombreFactura: '',
                    observacion: '',
                    listaventa: []
                },
                modalCuentaCobrar: false,
                modalEditarVenta: false,
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'venta_id',
                    order: 'descend'
                },
                loading: false
            }
        }, 
        
        mounted () {
			this.getAlmacenes()
            this.getTipoSalidas() 
			this.getTipoVentas()  
			this.getTipoUsuarios()  
            this.getClientes()
            this.getClientesNit()
            this.paginarResultados()
        },

        computed: {
			...mapGetters('auth', ['user']),
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('venta', ['ventas'])
		},

        methods: {
            ...mapActions('venta', ['getPaginacion']),

            openMenuQuery (name, query) {
				this.$router.push({
					name: name,
					params: { ventanaID: query }
				})
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
				this.consulta.fecha_final = dateString
                this.paginarResultados()
			},

            filterOptionCliente (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            filterOptionClienteNIT (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getAlmacenes () {
                axios.get(URI_ALMACEN_GET_LISTAR)
                .then(response => {
                    this.lista_almacenes = response.data
					this.consulta.almacen_id = Object.values(this.lista_almacenes).filter(x => typeof x !== 'undefined').shift().almacen_id
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getTipoSalidas () {
                axios.get(URI_TIPOSALIDA_GET_LISTAR_FACTURACION)
                .then(response => {
					this.lista_tiposalidas = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
			},
			
			getTipoUsuarios () {
                axios.get(URI_USUARIO_LISTAR)
                .then(response => {
                    this.lista_usuarios = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getTipoVentas () {
                axios.get(URI_PLANCUENTA_LISTAR)
                .then(response => {
					this.lista_plancuenta = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
            
            getClientes () {
                axios.get(URI_CLIENTE_LISTAR)
                .then(response => {
                    this.lista_clientes = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getClientesNit () {
                axios.get(URI_CLIENTE_LISTAR_NIT)
                .then(response => {
                    this.lista_nitclientes = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            resetConsultaPaginacion () {
                this.paginate = {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'venta_id',
                    order: 'descend'
                }

                this.consulta.tiposalida_id = 'Seleccionar'
				this.consulta.plancuenta_id = 'Seleccionar'
				this.consulta.onlyusuario = 'Seleccionar'
                this.consulta.cliente_id = 'Seleccionar'
                this.consulta.nit_cliente = 'Seleccionar'
                this.consulta.fecha_final = ''
                this.consulta.nombreTipoSalida = ''
                this.consulta.nombrePlanCuenta = ''
                this.consulta.nombreCliente = ''
                this.consulta.nombreClienteNIT = ''
                this.consulta.keyword = ''
            },

            paginarResultados () {
                this.consulta.almacen_id = (this.$can('transaccion.consultar.venta.por.sucursales')) ? this.consulta.almacen_id : this.user.almacenID
                this.consulta.onlyusuario = (this.$can('transaccion.consultar.venta.por.usuario')) ? this.consulta.onlyusuario : this.user.usuario

                this.getPaginacion({ ...this.paginate, ...this.consulta, ...this.user })
                .then(response => {
                    this.changePaginate(response.data.meta)
                    this.spinnerloading = false
                })
                .catch(error => {
                    this.loading = false
                    utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                })
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'venta_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.paginarResultados()
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'venta_id',
                    order: 'descend'
                }
            },

            obtenerListadoActual (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.paginarResultados()
            },

            onChageAlmacen (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.almacen_id = value.key
                this.consulta.nombreAlmacen = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onChageTipoSalida (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.tiposalida_id = value.key
                this.consulta.nombreTipoSalida = value.label
                this.paginarResultados()
            },

            onChageTipoVenta (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.plancuenta_id = value.key
                this.consulta.nombrePlanCuenta = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
			},
			
			onChageUsuario (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.onlyusuario = value.key
                this.consulta.nombreUsuario = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onChageCliente (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.cliente_id = value.key
                this.consulta.nombreCliente = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onChageClienteNit (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.cliente_id = value.key
                this.consulta.nombreClienteNIT = value.label
                this.estadoTipoSalida = 0
                this.paginarResultados()
            },

            onSearchSalida (value) {
                this.spinnerloading = true
                this.resetConsultaPaginacion()
                this.consulta.keyword = value
                this.paginarResultados()
            },

            abrirModalSalida (item) {
                this.spinnerloading = true
                this.detalle = {}
                setTimeout(function () {
                    this.detalle.codigoVenta = item.codigoVenta
                    this.detalle.fechaVenta = item.fechaVenta
                    this.detalle.horaVenta = item.horaVenta
                    this.detalle.montoTotal = item.montoTotal
                    this.detalle.descuento = item.descuento
                    this.detalle.totalVenta = item.totalVenta
                    this.detalle.efectivo = item.efectivo
                    this.detalle.cambio = item.cambio
                    this.detalle.usuario = item.usuario
                    this.detalle.cliente = item.cliente.nombreFactura
                    this.detalle.nitcliente = item.cliente.nitFactura
                    this.detalle.tipocambio = item.tipocambio
                    this.detalle.tipocambioValor = item.tipocambioValor
                    this.detalle.almacen = item.almacen.nombre
                    this.detalle.montoLiteral = item.montoLiteral
                    this.detalle.simbolo = item.simbolo
                    this.detalle.plancuentaID = item.plancuentaID
                    this.detalle.fechaCredito = item.fechaCredito
                    this.detalle.nombreMoneda = item.nombreMoneda
                    this.detalle.plancuenta = item.plancuenta.nombre
                    this.detalle.estado = item.estado
                    this.showModalSalidaListado = item.listaventa
                    this.detalle.listaServicios = item.servicioventas

                    this.modalDetalleSalida = true
                    this.spinnerloading = false
                }.bind(this), 500)
            },

            onClickPDF (id) {
                Swal.fire({
                    title: 'Desea Descargar el Reporte?',
                    text: 'El reporte a descargar es la Venta que se ha realizado anteriormente y su formato es en PDF tamaño Carta',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
                        window.location.assign('/libreria/venta/verlista/' + id)
                        Swal.fire('Realizado con exito', 'Puede abrir el Reporte o Visualizar el archivo descargado en su carpeta de Descargas ...', 'success')
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                        this.buttonKey++
                    }
                })
            },

            AnularVenta (id) {
                Swal.fire({
                    title: 'Desea Anular la Venta?',
                    text: 'Tome en cuenta que al anular la venta las cantidades volveran al inventario y debera realizar una nueva venta.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        const formData = new FormData()
                        formData.append('ventaID', id)
                                    
                        axios.post(URI_VENTA_ANULAR, formData)
                        .then(response => {
                            if (response.status === 201) {
                                utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
								this.paginarResultados()
								this.spinnerloading = false
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                        this.buttonKey++
                    }
                })
            },

            abrirFacturarCredito (item) {
				this.dataVenta.cliente = item.cliente
				this.dataVenta.codigoVenta = item.codigoVenta
				this.dataVenta.estado = item.estado
				this.dataVenta.estado_credito = item.estado_credito
				this.dataVenta.fechaVenta = item.fechaVenta
				this.dataVenta.horaVenta = item.horaVenta
				this.dataVenta.montoTotal = item.montoTotal
				this.dataVenta.tipocambio = item.tipocambio
				this.dataVenta.tiposalidaID = item.tiposalidaID
				this.dataVenta.plancuentaID = item.plancuentaID
				this.dataVenta.totalVenta = item.totalVenta
				this.dataVenta.usuario = item.usuario

				const formData = new FormData()
				formData.append('venta_id', (item.venta_id === 0) ? 0 : item.venta_id)
				axios.post(URI_VENTA_CONVERTIR_FACTURA, formData)
                .then(response => {           
					if (response.data.estado === 200 && response.data.save === true) {
						this.lista_venta_facturacion = response.data.venta
						this.cliente.nit = response.data.venta.clienteNIT
						this.cliente.nombre = response.data.venta.clienteNombre
						this.showModalFacturarCredito = true
                    } else if (response.data.estado === 500 && response.data.save === false) {
                        this.buttonKey++
                        var errors = response.data.errors
                        Swal.fire('Ups! Hubo un problema', 'Atención!!! hubo un error al importar los datos para la Facturacion', 'error') 
					}
				})
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
			},

            openModalCuentaCobrar (item) {
                this.venta.venta_id = item.venta_id
                this.modalCuentaCobrar = true
            },

            /** EDITAR VENTA */
            openModalEditarVenta (item) {
                this.venta = []
                this.venta.venta_id = ''
                this.venta.almacenID = ''
                this.venta.clienteID = ''
                this.venta.plancuentaID = ''
                this.venta.tiposalidaID = ''
                this.venta.codigoVenta = ''
                this.venta.fechaVenta = ''
                this.venta.listaventa = []
                this.venta.observacion = ''
                this.venta.nitFactura = ''
                this.venta.nombreFactura = ''
                    
                setTimeout(function () { 
                    this.venta.venta_id = item.venta_id
                    this.venta.almacenID = item.almacenID
                    this.venta.plancuentaID = item.plancuentaID
                    this.venta.tiposalidaID = item.tiposalidaID
                    this.venta.clienteID = item.clienteID
                    this.venta.codigoVenta = item.codigoVenta
                    this.venta.fechaVenta = item.fechaVentaOrigen
                    this.venta.listaventa = item.listaventa
                    this.venta.observacion = item.observacion
                    this.venta.nitFactura = item.cliente.nitFactura
                    this.venta.nombreFactura = item.cliente.nombreFactura
                    this.modalEditarVenta = true
                }.bind(this), 500)
            },

            dataSuccessEditarVenta (newValue) {
                setTimeout(function () {
                    this.paginarResultados()
                    this.modalEditarVenta = false
                    utils.openNotificationWithIcon('success', 'Mensaje : ', utils.showErrors(newValue), 'topRight')
                }.bind(this), 300)
            },

            imprimirTermico (ventaID) {
                utils.openReciboMobile(`/reporte/venta/termica/${ventaID}`)
            },

            reporteCarta (ventaID) {
                utils.openReciboMobile(`/reporte/venta/reporte/carta/${ventaID}`)
            }
        }
    }
</script>

<style scoped>
.invoice-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

@media (max-width: 576px) {
  .invoice-title {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>