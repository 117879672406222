<template>
    <div v-if="$can('transaccion.menu.consultar.venta')">
        <ModuloConsultarSalida
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :almacenid="usuario.almacenID"
            :habilitarsolicitante="config.habilitarsolicitante"
            :titulosolicitante="config.titulosolicitante" 
            :titulosolicitanteresponsable="config.titulosolicitanteresponsable" 
            :habilitarboletacobro="config.habilitarboletacobro"
            :monedaliteral="config.monedaliteral"
            :nuevaventanaventaid="1" 
            :ismarcaizquierdalistaventa="config.ismarcaizquierdalistaventa"
            :codigosolicitante="config.codigosolicitante"
            :habilitardescuentomanualventa="config.habilitardescuentomanualventa"
            :habilitardescuentoselectventa="config.habilitardescuentoselectventa"
            :deshabilitarvalidareditarprecioventa="config.deshabilitarvalidareditarprecioventa"
            :istotallistaproductoventa="config.istotallistaproductoventa"
            :habilitartipocambioventa="config.habilitartipocambioventa"
            :habilitararqueocaja="config.habilitararqueocaja"
            :habilitarcontrolcodigobarras="config.habilitarcontrolcodigobarras"
            :ventadecimal="config.ventadecimal"
            :generarlenghtcodigobarra="config.generarlenghtcodigobarra"
            :utilidadporcentaje="config.utilidadporcentaje"
			:statecodigobarradefault="config.statecodigobarradefault" 
			:disabledvencimiento="config.disabledvencimiento" 
			:preciocifradecimal="config.preciocifradecimal" 
			:preciocifraentera="config.preciocifraentera" 
			:precioreglatres="config.precioreglatres"
        />
    </div>
</template>

<script>
    import ModuloConsultarSalida from './Modulos/ModuloConsultarSalida.vue'

    export default {

        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloConsultarSalida
        }
    }
</script>