<template>
    <div class="row">

        <div class="col-md-12" style="top: -7px;">
            <a-button 
                v-if="detalle.estadoCredito === 'PENDIENTE'" 
                type="primary" 
                @click="openModalRealizarTransaccion"
                :disabled="estadoArqueoCaja === 'CerrarCaja' || estadoArqueoCaja === 'AbrirCaja'"
                > 
                <a-icon type="plus" />Realizar Nuevo Cobro
            </a-button>
            
            <a-button 
                @click="openModalDetalle"
                > 
                <a-icon type="file-search" />Detalle de la Venta
            </a-button>
        </div>

        <hr>

        <a-row type="flex" :gutter="16">
            <a-col flex="1 1 100px">
                <a-card title="Resumen de Credito">
                    <table>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">CODIGO VENTA:</td>
                            <td>{{ detalle.codigoVenta }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">FECHA REGISTRO:</td>
                            <td>{{ detalle.fechaVenta }}  {{ detalle.horaVenta }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">CLIENTE REGISTRADO:</td>
                            <td>{{ detalle.cliente }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">SUCURSAL:</td>
                            <td>{{ detalle.almacen }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">MONTO ORIGINAL:</td>
                            <td>{{ detalle.simbolo }}  {{ detalle.totalVenta }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">SALDO ACTUAL:</td>
                            <td>{{ detalle.simbolo }}  {{ detalle.saldoActual }}</td>
                        </tr>             
                    </table>
                </a-card>
            </a-col>

            <a-col flex="1 1 400px">
                <a-card title="Detalle de Cobros">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Codigo Cuenta</th>
                                <th>Fecha</th>
                                <th>Hora</th>
                                <th>Monto</th>
                                <th>Saldo</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in listacuentacobrar" :key="index">
                                <td>{{ item.codigoCuentaCobrar }}</td>
                                <td>{{ item.fechaPagoCobrar }}</td>
                                <td>{{ item.horaPagoCobrar }}</td>
                                <td>{{ item.montoCanceladoCobrar }}</td>
                                <td>{{ item.saldoActualCobrar }}</td>
                                <th>
                                    <a-button type="dashed" block @click.prevent="reporteCarta(item.cuentacobrar_id)" style="font-size:12px;">
                                        <a-icon type="file-pdf" :style="{ fontSize: '15px', color: '#fa1d03' }"/>Comprobante
                                    </a-button>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </a-card>
            </a-col>
        </a-row>

        <!-- DETALLE COMPRA -->
        <a-modal
            v-model="modalDetalle"
            title="Detalle Venta"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="760"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="row">
                                <div class="col-md-12">
                                    <div class="invoice-title">
                                        <h3>CODIGO DE VENTA</h3><h3 class="pull-right"><strong># {{ detalle.codigoVenta }}</strong></h3>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <address>
                                            <strong>Datos Registrados: </strong><br>
                                                Almacen / Sucursal : {{ detalle.almacen }}<br>
                                                Cliente : {{ detalle.cliente }}<br>
                                                Direccion : {{ detalle.direccion }}<br>
                                                NIT / CI : {{ detalle.nit }}<br>
                                                Observaciones : {{ detalle.observacion }}
                                            </address>
                                        </div>
                                        <div class="col-xs-6 text-right">
                                            <address>
                                            <strong>Datos Registrados:</strong><br>
                                                Tipo de Salida : {{ detalle.tiposalida }}<br>
                                                Tipo de Venta : {{ detalle.plancuenta }}<br>
                                                Fecha de Registro : {{ detalle.fechaRegistro }}<br>
                                                Fecha de Venta : {{ detalle.fechaVenta }}
                                            </address>
                                        </div>
                                    </div>
                                    <div class="panel panel-default">
                                        <div class="panel-body">
                                            <div class="col-xs-12 row">
                                                <strong>DATOS DE VENTA CON FACTURA: </strong><br>
                                                Nro de FACTURA : <strong>{{ detalle.nrofactura }}</strong><br>
                                                Nro de AUTORIZACION : {{ detalle.nroautorizacion }}<br>
                                                CODIGO DE CONTROL : {{ detalle.codigocontrol }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                                        </div>
                                        <div class="panel-body">
                                            <div class="box-body table-responsive no-padding">
                                                <table class="table table-condensed">
                                                    <thead>
                                                        <tr>
                                                            <th><strong>ITEM</strong></th>
                                                            <th class="text-center"><strong>ARTICULO</strong></th>
                                                            <th class="text-center"><strong>CANT</strong></th>
                                                            <th class="text-center"><strong>P.VENTA</strong></th>
                                                            <th class="text-center"><strong>DESC</strong></th>
                                                            <th class="text-right"><strong>SUBTOTAL</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in detalle.listaventas" :key="index">
                                                            <td>{{ item.nral }}</td>
                                                            <td class="text-center">{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                            <td class="text-center">{{ item.cantidad }}</td>
                                                            <td class="text-center">{{ item.precioVenta }}</td>
                                                            <td class="text-center">{{ item.descuento }}</td>
                                                            <td class="text-right">{{ item.subtotal }}</td>
                                                        </tr>
                                                    </tbody> 
                                                    <tfoot>       
                                                        <tr>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                                            <td class="thick-line text-right">{{ monedasimbolo }} {{ detalle.montoTotal }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line text-center"><strong>Descuento</strong></td>
                                                            <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.descuento }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line text-center"><strong>Total</strong></td>
                                                            <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.totalVenta }}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalle = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <!--REALIZANDO PAGOS-->
        <a-modal
            v-model="modalRealizarTransaccion"
            title="REALIZAR NUEVO COBRO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1049"
            >

            <div class="row mb-12">
                <label for="0" style="font-size:11px; font-weight:700;">
                    <input type="radio" v-model="selectedMoney" v-bind:value="0">
                    {{ monedaliteral }}&nbsp;&nbsp; 
                </label>
                <label :for="i" v-for="(aalabel, i) in listMoney" :key="i" style="font-size:11px; font-weight:700;">
                    <input type="radio" v-model="selectedMoney" v-bind:value="aalabel.tipocambio_id">
                    {{ aalabel.nombre }}&nbsp;&nbsp; 
                </label><br>

                <a-descriptions :title="`Código : ${detalle.codigoVenta}`" layout="vertical">
                    <a-descriptions-item label="MONTO ORIGINAL">
                        {{ detalle.simbolo }} {{ detalle.totalVenta }}
                    </a-descriptions-item>
                    <a-descriptions-item label="SALDO ACTUAL">
                        {{ detalle.simbolo }} {{ detalle.saldoActual }}
                    </a-descriptions-item>
                </a-descriptions>

                <div class="row">
                    <div class="col-md-12">
                        <label class="d-sm-block">Importe a Cobrar:</label>
                        <strong>
                            <a-input-number 
                                size="large" 
                                :min="0" 
                                :max="100000" 
                                :default-value="cuenta.importe" 
                                @keypress="onlyNumber" 
                                @change="onChangeImporte"
                                style="width:100%; height:50px; font-size:27px;  border-width:2px; border-color:orange; border-style:solid;" 
                                aria-placeholder="0.00"
                            />
                        </strong>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button 
                    size="large" 
                    type="primary" 
                    @click.once="realizarTransaccion()" 
                    block
                    :key="buttonKey"
                    :disabled="estadoArqueoCaja === 'CerrarCaja' || estadoArqueoCaja === 'AbrirCaja'"
                    >
                    <a-icon type="plus" /> COBRAR AHORA
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1040;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

    </div>
</template>

<script>
    import Cookies from 'js-cookie'
	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
    import { mapActions, mapGetters } from 'vuex'
	import Swal from 'sweetalert2'
    import numeral from 'numeral'

    import { 
		URI_PRINCIPAL_TIPO_CAMBIO,
        URI_CUENTACOBRAR_GUARDAR,
        URI_CUENTACOBRAR_LISTAR_COBRAR,
        URI_CUENTACOBRAR_LISTAR,
        ALMACEN_SISTEMA_KEY_ID
	} from '@/utils/constants'

    export default {

        props: [
            'ventaid', 
            'monedasimbolo'
        ],

        data () {
            return {
                utils,
                detalle: {
                    almacen: '',
                    cliente: '',
                    plancuenta: '',
                    tiposalida: '',
                    codigoVenta: '',
                    fechaIngreso: '',
					horaIngreso: '',
                    fechaVenta: '',
                    montoTotal: '',
                    descuento: '',
                    totalVenta: '',
                    direccion: '',
                    nit: '',
                    observacion: '',
					simbolo: '',
                    saldoActual: '',
                    estadoCredito: '',
                    listaventas: []
                },
                buttonKey: 10,
                clicked: false,
                cuenta: {
                    importe: 0
                },
                listacuentacobrar: [],
				estadoCobro: false, 
				selectedMoney: 0,
				listMoney: [],
                modalDetalle: false,
                modalRealizarTransaccion: false,
                monedaliteral: '',
                spinnerloading: false,
                estadoArqueoCaja: ''
            }
        }, 

        mounted () {
            this.getListado()
			this.getListaCobrar()
			this.getMoney()

            if (this.config.habilitararqueocaja === 'ACTIVADO') { // A nivel del Sistema
                if (this.$can('manager.arqueo.de.caja') === true) { // A nivel de Permisos
                    this.controlArqueoCajaConMensaje()
                    setInterval(function () {
                        this.controlArqueoCaja()
                    }.bind(this), 5000)
                }
            }
        },

        computed: {
            ...mapGetters('configuracion', ['config']),
            ...mapGetters('auth', ['user']),
            isDisabled () {
                if (this.estadocuentapagar === 'desactivado') {
                    return true
                } else {
                    return false
                }
            }
        },

        methods: {
            ...mapActions('arqueocaja', ['controlArqueoCajaCerrada']),

            onlyNumber ($event) {
				const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault()
				}
			},

            onChangeImporte (value) {
				this.cuenta.importe = value
			},

            openModalDetalle () {
                this.modalDetalle = true
            },

            openModalRealizarTransaccion () {
                this.modalRealizarTransaccion = true
            },

			getMoney () {
                axios.get(URI_PRINCIPAL_TIPO_CAMBIO)
                .then(response => {
                    this.listMoney = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getListado () {
                this.spinnerloading = true
                const formData = new FormData()
                formData.append('ventaID', this.ventaid)
                
                axios.post(URI_CUENTACOBRAR_LISTAR_COBRAR, formData)
                .then(response => {
                    this.detalle.almacen = response.data.almacen.nombre
                    this.detalle.cliente = response.data.nombreCliente
                    this.detalle.plancuenta = response.data.nombrePlanCuenta
                    this.detalle.tiposalida = response.data.nombreTipoSalida
                    this.detalle.codigoVenta = response.data.codigoVenta
                    this.detalle.fechaIngreso = response.data.fechaVenta
					this.detalle.horaVenta = response.data.horaVenta
                    this.detalle.fechaVenta = response.data.fechaVenta
                    this.detalle.montoTotal = response.data.montoTotal
                    this.detalle.descuento = response.data.descuento
                    this.detalle.totalVenta = response.data.totalVenta
                    this.detalle.direccion = ''
                    this.detalle.nit = response.data.cliente.nitFactura
					this.detalle.observacion = ''
					this.detalle.simbolo = response.data.simbolo
					this.detalle.nroautorizacion = response.data.autorizacion
					this.detalle.nrofactura = response.data.codigoFactura
					this.detalle.codigocontrol = response.data.codigoControl
                    this.detalle.saldoActual = response.data.saldoActual
                    this.detalle.estadoCredito = response.data.estado_credito
                    this.detalle.listaventas = response.data.listaventas

                    this.spinnerloading = false
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })

                this.showModal = true
            },

            getListaCobrar () {
                const formData = new FormData()
                formData.append('ventaID', this.ventaid)

                axios.post(URI_CUENTACOBRAR_LISTAR, formData)
                .then(response => {
                    this.listacuentacobrar = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
                this.showModal = true
            },

            realizarTransaccion () {
                const MontoOriginal = parseFloat(numeral(this.detalle.totalVenta).value())
				const ImporteCobrar = parseFloat(numeral(this.cuenta.importe).value())

                if (ImporteCobrar > MontoOriginal) {
                    Swal.fire('No Realizado', 'El Monto a Pagar es mayor al Monto Total...', 'error')
                    this.buttonKey++
                } else if (ImporteCobrar <= 0) {
                    Swal.fire('No Realizado', 'El importe a Pagar es igual a Cero, Ingrese un Mayor Monto...', 'error')
                    this.buttonKey++
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                    }).then((result) => {
                        if (result.value) {
                            this.spinnerloading = true
                            const ecode = new EuropioCode()
                            const formData = new FormData()
                            formData.append('ventaID', this.ventaid)
                            formData.append('montoCanceladoCobrar', ecode.encode(this.cuenta.importe.toString()))
                            formData.append('montoOriginal', ecode.encode(MontoOriginal.toString()))
                            formData.append('tipocambio', parseInt(this.selectedMoney))

                            axios.post(URI_CUENTACOBRAR_GUARDAR, formData)
                            .then(response => {
                                if (response.status === 201) {
                                    this.cuenta.importe = '0.00'
                                    this.getListado()
                                    this.getListaCobrar()
                                    this.modalRealizarTransaccion = false
                                    this.spinnerloading = false
                                    Swal.fire('Éxito!', 'Has Realizado Correctamente el Cobro.', 'success')
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
				}

                this.buttonKey++
            },

            reporteCarta (cuentacobrarID) {
                utils.openReciboMobile(`/reporte/cuentacobrar/reporte/carta/${cuentacobrarID}`)
            },

            controlArqueoCaja () {
				const almacenID = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
				this.controlArqueoCajaCerrada(almacenID)
                .then(response => {
                    if (response.data.estado === 'CERRADO') {
						this.estadoArqueoCaja = 'AbrirCaja'
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'EXPIRADO') {
						this.estadoArqueoCaja = 'CerrarCaja'
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'ABIERTO') {
						this.estadoArqueoCaja = 'Normal'
					}
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
			},

            controlArqueoCajaConMensaje () {
				const almacenID = (this.user) ? this.user.almacenID.toString() : Cookies.get(ALMACEN_SISTEMA_KEY_ID)
				this.controlArqueoCajaCerrada(almacenID)
                .then(response => {
                    if (response.data.estado === 'CERRADO') {
						this.estadoArqueoCaja = 'AbrirCaja'
                        utils.openNotificationWithIcon('info', 'Arqueo de Caja', 'El arqueo de caja esta cerrado, debe (abrir) aperturar el arqueo de caja para seguir realizando las operaciones de venta.', 'topRight')
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'EXPIRADO') {
						this.estadoArqueoCaja = 'CerrarCaja'
                        utils.openNotificationWithIcon('info', 'Arqueo de Caja', 'El arqueo de caja ha expirado, debe cerrar y aperturar la caja nuevamente para seguir realizando las operaciones de venta.', 'topRight')
                        if (this.$can('manager.arqueo.de.caja') === true) {
                            this.modalArqueoCaja = true
                        }
					} else if (response.data.estado === 'ABIERTO') {
						this.estadoArqueoCaja = 'Normal'
					}
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje:', utils.showErrors(error.response.data), 'topRight')
                })
			}
        }
    }
</script>

<style>
        .example-modal .modal {
          position: relative;
          top: auto;
          bottom: auto;
          right: auto;
          left: auto;
          display: block;
          z-index: 1;
        }

        .example-modal .modal {
          background: transparent !important;
        }
</style>